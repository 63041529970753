import 'bootstrap/dist/css/bootstrap.css';
import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { Container } from './styles';
import text from './text/text';
import PoliticaSegurancaCibernetica from '../../../images/pdf/segurancaCibernetica/politica_seguranca_cibernetica_hub_fintech.pdf'
import PoliticaSegurancaInfo from '../../../images/pdf/segurancaCibernetica/politica_seguranca_informacao.pdf';

export default function BodyOuvidoria() {
  const { idioma } = useContext(LanguageContext);

  var body = text(PoliticaSegurancaInfo, PoliticaSegurancaCibernetica);

  return (
    <Container>
      <div>
        {Parser(body[idioma])}
      </div>
    </Container>
  );
}
