
export default function text(politicaSegInfo,politicaSegCiberUrl) {

  var body=[
    //PT-BR
    `
    <p><b>O que diz a Resolução nº 85/2021 do Banco Central ?</b></p>
    <p>A Resolução BCB nº 85, dispõe sobre a política de segurança cibernética e sobre os requisitos para a contratação de serviços de processamento e armazenamento de dados e de computação em nuvem a serem observados pelas instituições de pagamento autorizadas a funcionar pelo Banco Central do Brasil, entrou em vigor  em 8 de abril de 2021.</p>
    <p><b>O que é a cultura de Segurança Cibernética?</b></p>
    <p>A Resolução supracitada menciona em seu artigo 3º, inciso VI, que as Instituições de Pagamento devem  implantar “os mecanismos para disseminação da cultura de segurança cibernética na instituição de pagamento, incluindo a prestação de informações aos usuários finais sobre precauções na utilização de produtos e serviços oferecidos”.</p>
    <p>Nesse contexto, compartilhamos a 
    <a
        href=${politicaSegCiberUrl}
        download
        >
        Política de Segurança Cibernética da Hub Fintech
      </a>, em seu anexo 8, consta a Cartilha de recomendações e Instruções de Segurança Cibernética para Clientes e Usuários.</p>
    <p>Adicionalmente, disponibilizamos o
      <a
        href="https://especiais.magazineluiza.com.br/seguranca/"
        target="_blank"
        >
      Portal de Segurança e Privacidade do Grupo Magazine Luiza
      </a>, que fornece informações como:
    </p>
    <ul>
      <li>Como identificar um perfil de site falso;</li>
      <li>Como se proteger e manter seus dados pessoais seguros;</li>
      <li>Formas de pagamento;</li>
      <li>Fique atento a golpes: Golpe do Whats app e SMS; Golpe do link falso; Cuidado com as senhas; Golpe do falso boleto; e Golpe do trabalho remoto.</li>
    </ul>
    <p><strong>Atenção:</strong> Nunca <strong>clique</strong> ou abra <strong>links</strong> enviados por <strong>e-mails e mensagens suspeitas</strong> com tom de urgência, erros gramaticais ou com preços/promoções diferentes do site oficial. Para sua segurança, <strong>digite você mesmo</strong> o endereço do nosso site ou acesse nosso aplicativo.</p>
    <p>
      <a
        href="https://magazineluiza.semfraude.online/"
        target="_blank"
        >
      Denuncie
      </a>
      perfis e sites falsos, que faremos análise para comprovar a fraude e trataremos o caso o mais breve possível.
    </p>`,

    //INGLÊS

    `<p> On this page we provide information related to information security and cyber security policy, enabling transparency in any information shared on this site. </p>
    <p> <a
        href=${politicaSegInfo}
        download
        >
        Information security
      </a> </p>
    <p> <b> What does Central Bank Resolution 85/2021 say? </b> </p>
    <p> Resolution BCB No. 85, provides for the cyber security policy and the requirements for hiring data processing and cloud computing services to be observed by the payment institutions authorized by the Central Bank ofBrazil, entered into force on April 8, 2021. </p>
    <p> <b> What is cyber security culture? </b> </p>
    <p> The above resolution mentions in its article 3, item VI, that payment institutions must implement “the mechanisms for disseminating cyber security culture in the payment institution, including the provision of information to end users on precautions on the use of the use ofproducts and services offered ”. </p>
    <p> In this context, we share the
    <a
        href=${politicaSegCiberUrl}
        download
        >
        Hub Fintech Cyber Security Policy
      </a>, in its annex 8, there is the booklet of cyber security recommendations and instructions for customers and users. </p>
    <p> In addition, we provide the
      <a
        href="https://especias.magazineluiza.com.br/seguranca/"
        target="_blank"
        >
      Safety and Privacy Portal of the Magazine Luiza Group
      </a>, which provides information such as:
    </p>
    <ul>
      <li> How to identify a fake website profile; </li>
      <li> How to protect and keep your personal data safe; </li>
      <li> Payment forms; </li>
      <li> Stay tuned for blows: Whats App and SMS blow;Fake link scam;Beware of passwords;Bullet's blow;and scam of remote work. </li>
    </ul>
    <p> <strong> Attention: </strong> Never <strong> Click </strong> or Abra <strong> Links </strong> sent by <strong> emails and suspicious messages </strong>, grammatical errors or with different prices/promotions of the official website.For your safety, <strong> Enter yourself </strong> the address of our site or go to our application. </p>
    <p>
      <a
        href="https://magazineluiza.semfraude.online/"
        target="_blank"
        >
      report it
      </a>
      Fake profiles and websites, which we will analyze to prove the fraud and treat the case as soon as possible.
    </p>`,

    //ESPANHOL
    `<p> En esta página brindamos información relacionada con la seguridad de la información y la política de seguridad cibernética, lo que permite la transparencia en cualquier información compartida en este sitio. </p>
    <p> <a
        href=${politicaSegInfo}
        download
        >
        Seguridad de información
      </a> </p>
    <p> <b> ¿Qué dice la Resolución 85/2021 del Banco Central? </b> </p>
    <p>La Resolución BCB N° 85, establece la política de seguridad cibernética y los requisitos para la contratación de servicios de procesamiento de datos y computación en la nube que deben observar las instituciones de pago autorizadas por el Banco Central de Brasil, entró en vigor el 8 de abril de 2021. < /p>
    <p> <b> ¿Qué es la cultura de seguridad cibernética? </b> </p>
    <p> La citada resolución menciona en su artículo 3, inciso VI, que las instituciones de pago deberán implementar “los mecanismos para difundir la cultura de ciberseguridad en la institución de pago, incluyendo el suministro de información a los usuarios finales sobre precauciones en el uso de los productos”. y servicios ofrecidos”. </p>
    <p> En este contexto, compartimos la
    <un
        Href=${politicaSegCiberUrl}
        download
        >
        Política de seguridad cibernética de Hub Fintech
      </a>, en su anexo 8, se encuentra el folleto de recomendaciones e instrucciones de ciberseguridad para clientes y usuarios. </p>
    <p> Además, proporcionamos la
      <un
        href="https://especias.magazineluiza.com.br/seguranca/"
        target="_blank"
        >
      Portal de Seguridad y Privacidad del Grupo Magazine Luiza
      </a>, que proporciona información como:
    </p>
    <ul>
      <li> Cómo identificar el perfil de un sitio web falso; </li>
      <li> Cómo proteger y mantener seguros sus datos personales; </li>
      <li> Formas de pago; </li>
      <li> Estén atentos a los golpes: golpe de Whats App y SMS; estafa de enlaces falsos; cuidado con las contraseñas; golpe de bala; y estafa del trabajo remoto. </li>
    </ul>
    <p> <strong> Atención: </strong> Nunca <strong> Haga clic </strong> o Abra <strong> Enlaces </strong> enviados por <strong> correos electrónicos y mensajes sospechosos </strong>, con errores gramaticales o con diferentes precios/promociones del sitio web oficial. Para su seguridad, <strong> Ingrese usted mismo </strong> la dirección de nuestro sitio o vaya a nuestra aplicación. </p>
    <p>
      <a
        href="https://magazineluiza.semfraude.online/"
        target="_blank"
        >
      reportalo
      </a>
      Perfiles y sitios web falsos, que analizaremos para demostrar el fraude y tratar el caso lo antes posible.
    </p>`
  ];

  return body;
}
