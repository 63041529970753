import React from 'react';
import Chat from '../components/Chat';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import HeaderSegurancaCibernetica from '../components/SegurancaCibernetica';
import BodySegurancaCibernetica from '../components/SegurancaCibernetica/Body';
import SEO from '../components/seo';

export default function SegurancaCibernetica() {
  return (
    <Layout>
      <SEO title="Segurança Cibernética" />
      <HeaderSegurancaCibernetica />
      <BodySegurancaCibernetica />
      <Footer />
      <Chat />
    </Layout>
  );
}
