import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
import Menu from '../Menu';
import { Container, Header } from './styles';

export default function HeaderSegurancaCibernetica() {
  const { idioma } = useContext(LanguageContext);
  const title = ['Segurança Cibernética', 'Cybersecurity', 'Seguridad Cibernética'];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          <b>{title[idioma]}</b>
        </h2>
      </Header>
      <Menu scrollToForm={() => console.log('to form')} />
    </Container>
  );
}
